<template>
  <AssetTypeVideo />
  <div class="container h-100 d-flex align-items-center justify-content-center">
    <div class="row mobile align-content-between g-0">
      <TypeTopNav backButton="" showLogo="true" />

      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="row g-0">
          <div class="col-12" v-if="collection">

            <template v-for="asset in assetTypes" :key="asset.Guid">
              <!-- Video links -->
              <router-link v-if="asset.LinkName === 'videos'" :to="{
                name: 'AssetVideoList',
                params: {
                  assetType: this.assetType,
                  viewType: asset.LinkName,
                  lid: collection.videoLibraryId,
                  collectionId: collection.guid
                }
              }" class="btn-theme-menu mb-2">
                {{ asset.ObjectName }}
              </router-link>

              <!-- Panorama links -->
              <router-link
                v-if="asset.LinkName === 'panoramas' || asset.LinkName === 'virtual-tour' || asset.LinkName === 'balcony-views'"
                :to="{
                  name: asset.ComponentName,
                  params: {
                    assetType: this.assetType,
                    viewType: asset.LinkName
                  }
                }" class="btn-theme-menu mb-2">
                <img :src="theme.panoramasIcon" :alt="asset.ObjectName" class="me-3">{{ asset.ObjectName }}
              </router-link>

              <!-- Renders links -->
              <!-- <router-link
                v-if="asset.LinkName === 'renders' || asset.LinkName === 'range'"
                :to="{
                  name:  asset.ComponentName,
                  params: {
                    assetType: this.assetType,
                    viewType: asset.LinkName
                  }
                }"
                class="btn-theme-menu mb-2"
              >
                <img :src="theme.rendersIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
              </router-link> -->

              <!-- Floorplans links -->
              <router-link v-if="asset.LinkName === 'floorplans'" :to="{
                name: asset.ComponentName,
                params: {
                  assetType: this.assetType,
                  viewType: asset.LinkName
                }
              }" class="btn-theme-menu mb-2">
                <img :src="theme.floorplansIcon" :alt="asset.ObjectName" class="me-3">{{ asset.ObjectName }}
              </router-link>

              <!-- PDF links -->
              <router-link v-if="asset.LinkName === 'pdf'" :to="{
                name: asset.ComponentName,
                params: {
                  assetType: this.assetType,
                  viewType: asset.LinkName
                }
              }" class="btn-theme-menu mb-2">
                <img :src="theme.floorplansIcon" :alt="asset.ObjectName" class="me-3">{{ asset.ObjectName }}
              </router-link>
            </template>

            <a  :href="generateMailToLink" class="btn-theme-menu btn-theme-menu-green mb-2"><img src="/img/ui-theme/heart.png" class="mr-2" style="
                padding-right: 10px;
              "><strong>Email Councillors My Support In </strong><strong style="
                  padding: 0 5px;
              ">1 CLICK
              </strong>
              <img src="/img/ui-theme/heart.png" class="mr-2">
                  
            </a>
          </div>
        </div>
      </div>
      <ModalComponent v-if="openModal" @updateOpenValue="updateOpenModalComponent"></ModalComponent>

      <TypeBotNav />
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import AssetTypeVideo from '@/components/AssetTypeVideo.vue'
import TypeTopNav from '@/components/TypeTopNav.vue'
import TypeBotNav from '@/components/TypeBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'
import ModalComponent from '@/components/Modal.vue';

export default {
  name: "AssetType",
  // props: {
  //   assetType: String
  // },
  components: {
    AssetTypeVideo,
    TypeTopNav,
    TypeBotNav,
    ModalComponent
  },
  data() {
    return {
      assetTypes: null,
      collection: null,
      componentName: "AssetView",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + 'Master Plan Flythrough' + '/'),
      assetOrder: null,
      openModal: false,
      assetType: 'Master Plan Flythrough',
      toEmail:  ['councillors@frasercoast.qld.gov.au'],
      bccEmail: ['herveybaycommunity@gmail.com'],
      subject: 'PLEASE APPROVE THE SHERATON HERVEY BAY',
      body: "Dear Councillors,\n\n I am a resident of the Fraser Coast writing to you to indicate my support for the Sheraton Hervey Bay project.\n\nI want the project to go ahead for the following reasons …."
    };
  },
  methods: {
    updateOpenModalComponent() {
      this.openModal = !this.openModal;
    },
  },
  computed: {
    generateMailToLink() {
      const toParam = encodeURIComponent(this.toEmail);
      const bccParam = encodeURIComponent(this.bccEmail);
      const subjectParam = encodeURIComponent(this.subject);
      const bodyParam = encodeURIComponent(this.body);

      const mailtoLink = `mailto:${toParam}?&subject=${subjectParam}&body=${bodyParam}&bcc=${bccParam}`;
      return mailtoLink;
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
      .then(response => {
        // Remap array
        const menuItems = response.data.map((item) => {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
          };
        });

        // Assign type menu array
        if (this.assetType === 'Lobby' || this.assetType === 'Lobby Bar' || this.assetType === 'Rooftop Lounge' || this.assetType === 'Master Plan Flythrough' || this.assetType === 'Typical Hotel Room' || this.assetType === 'Typical 2 Bed Apartment' || this.assetType === 'Economics') {
          this.assetOrder = this.theme.bed1MenuOrder
        } //else if (this.assetType === '2-bed') {
        //this.assetOrder = this.theme.bed2MenuOrder
        //}

        // Sort array
        const customMenu = sortArray(menuItems, {
          by: ['LinkName'],
          order: 'LinkName',
          customOrders: {
            LinkName: this.assetOrder
          }
        })

        console.log(customMenu)

        this.assetTypes = customMenu
        console.log(this.assetTypes)
      })
      .catch(error => {
        console.log(error)
      }),

      // api call get video collection details
      BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType)
        .then(response => {

          this.collection = response.data[0]
        })
        .catch(error => {
          console.log(error)
        })
  },
  setup() {
    // load global config
    const { theme } = global

    return {
      theme
    }
  }
}
</script>
